import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './auth.context';
import { useWebSocket } from './WebSocketProvider';
import { fetchBalance, sendBalanceToParent } from '../services/balance.service';

const BalanceUserContext = createContext(null);

export const BalanceUserProvider = ({ children }) => {
    const [BalanceUser, setBalanceUser] = useState(0);
    const { isLoggedIn, user } = useContext(AuthContext);  // Access authentication context
    const { balanceUpdated } = useWebSocket();  // Access WebSocket context

    // Function to fetch balance from the server
    const getBalance = async () => {
        if (!isLoggedIn || !user) return;

        try {
            const balance = await fetchBalance();
            if (BalanceUser === 0) {
                setTimeout(() => sendBalanceToParent(balance), 2000);  // Send balance to parent window
            }

            if (BalanceUser !== 0) {
                await sendBalanceToParent();
            }
            setBalanceUser(balance);
        } catch (error) {
            console.error('Error fetching balance:', error.message);
        }
    };

    // Update balance based on WebSocket messages
    useEffect(() => {
        if (isLoggedIn && user) {
            // Initial fetch
            getBalance();

            // Set up interval to fetch balance every 10 seconds
            const intervalId = setInterval(() => {
                getBalance();
            }, 10000); // 10000 milliseconds = 10 seconds

            // Cleanup interval on unmount or when dependencies change
            return () => clearInterval(intervalId);
        }
    }, [isLoggedIn, user, balanceUpdated]);  // Rerun when bets or user status change

    return (
        <BalanceUserContext.Provider value={{ BalanceUser, setBalanceUser }}>
            {children}
        </BalanceUserContext.Provider>
    );
};

export const useBalanceUser = () => useContext(BalanceUserContext);
