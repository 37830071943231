// App.js
import "./App.css";
import React, { useEffect, useContext, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from 'jwt-decode';
import authService from "./services/auth.service";
import { AuthContext } from "./context/auth.context";
import { sendBalanceToParent } from './services/balance.service';

import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import Navbar from "./components/Navbar/Navbar";
import CasinoStatistics from "./components/CasinoDashboard/CasinoDashboard";

import IsPrivate from "./components/IsPrivate/IsPrivate";
/* import IsAnon from "./components/IsAnon/IsAnon";
import IsAdmin from "./components/IsAdmin/IsAdmin"; */
import IsCasino from "./components/IsCasino/IsCasino";
import CasinoCryptocoins from "./components/CasinoDashboard/CasinoCryptocoins";
import CasinoUsers from "./components/CasinoDashboard/CasinoUsers";
import CasinoBots from "./components/CasinoDashboard/CasinoBots";
import CasinoBets from "./components/CasinoDashboard/CasinoBets";
import CasinoColors from "./components/CasinoDashboard/CasinoColors";
import CasinoSettings from "./components/CasinoDashboard/CasinoSettings";
import CasinoColorsEdit from "./components/CasinoDashboard/CasinoColorsEdit";

function App() {
  const { storeToken, authenticateUser } = useContext(AuthContext);
  const [isEmbeddingAllowed, setIsEmbeddingAllowed] = useState(false);
  const navigate = useNavigate()
  const trustedDomains = [
    'https://casino-test-4r57.vercel.app'
  ];

  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set the initial value
    handleResize();

    // Recalculate on resize
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to check if the token is expired
  const isTokenExpired = (decodedToken) => {
    if (!decodedToken.exp) {
      // If no exp claim, consider the token invalid or handle accordingly
      return true;
    }
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime;
  };

  const handleCasinoLogin = async (userId, casinoId, username, role, token) => {
    const requestBody = { userId, casinoId, username, role, token };

    try {
      const response = await authService.casinoLogin(requestBody);
      storeToken(response.data.authToken);

      const user = await authenticateUser();

      // Navigate based on user role
      if (user.role === "casino") {
        navigate("/casino/dashboard");
      } else if (user.role === "admin") {
        navigate("/admin-dashboard");
      }
    } catch (error) {
      console.error("Casino Login Error:", error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    const isInIframe = window.self !== window.top;

    if (isInIframe) {
      const storedToken = localStorage.getItem('casinoToken');
      let isTrusted = false;

      if (storedToken) {
        try {
          const decoded = jwtDecode(storedToken);
          if (!isTokenExpired(decoded)) {
            isTrusted = true;
            // Automatically handle login with stored token
            const { userId, casinoId, username, role } = decoded;
            handleCasinoLogin(userId, casinoId, username, role, storedToken);
          } else {
            localStorage.removeItem('casinoToken');
          }
        } catch (error) {
          console.warn('Error decoding stored token:', error);
        }
      }

      if (!isTrusted) {
        // Fall back to referrer check
        const referrer = document.referrer;

        if (referrer) {
          try {
            const referrerUrl = new URL(referrer);
            const referrerOrigin = referrerUrl.origin;

            // Check if the referrer is in the trusted domains list
            isTrusted = trustedDomains.includes(referrerOrigin);
          } catch (error) {
            console.warn('Error parsing referrer URL:', error);
          }
        } else {
          console.warn('No referrer detected.');
        }

        if (!isTrusted) {
          setIsEmbeddingAllowed(false);
          return;
        }
      }

      // If trusted, set embedding allowed
      setIsEmbeddingAllowed(true);
    } else {
      // Not in an iframe, so embedding is allowed
      setIsEmbeddingAllowed(true);
    }
  }, []);

  useEffect(() => {
    if (!isEmbeddingAllowed) return;

    const parentOrigin = 'https://casino-test-4r57.vercel.app/'; // Parent origin
    const reactOrigin = window.location.origin; // Iframe's own origin

    // Function to send messages to parent
    const sendMessageToParent = (message) => {
      window.parent.postMessage(message, parentOrigin);
    };

    // Listen for messages from parent
    const handleMessage = async (event) => {
      const { origin, data } = event;

      if (origin === reactOrigin) {
        return;
      }

      // Security Check: Ensure the message is from the parent
      if (origin !== parentOrigin) {
        console.warn('Unauthorized origin:', origin);
        return;
      }

      // Validate message structure
      if (!data || typeof data !== 'object' || data.type !== 'TOKEN') {
        console.warn('Invalid or unexpected message type:', data);
        return;
      }

      const { token } = data.payload;

      // Ensure token is present
      if (!token) {
        console.warn('Missing token in the message payload.');
        return;
      }

      // Decode the token to extract user information
      let decoded;
      try {
        decoded = jwtDecode(token);
      } catch (err) {
        console.error('Error decoding token:', err);
        return;
      }

      const { userId, casinoId, username, role } = decoded;

      // Ensure userId and casinoId are present
      if (!userId || !casinoId) {
        console.warn('Missing userId or casinoId in the decoded token.');
        return;
      }

      // Check if the token has expired
      if (isTokenExpired(decoded)) {
        console.warn('Received token is expired.');
        // Inform the parent about the expired token
        const errorMessage = {
          type: 'ERROR',
          payload: {
            status: 'failed',
            message: 'Token has expired. Please log in again.',
          },
        };
        sendMessageToParent(errorMessage);
        return;
      }

      // Store the token
      localStorage.setItem('casinoToken', token);

      // Handle casino login
      await handleCasinoLogin(userId, casinoId, username, role, token);

      // Respond back to parent indicating receipt
      const confirmation = {
        type: 'CONFIRMATION',
        payload: {
          status: 'received',
          message: 'Token received successfully.',
        },
      };
      sendMessageToParent(confirmation);

      // After sending confirmation, fetch and send the balance
      await sendBalanceToParent();
    };

    // Listen for messages
    window.addEventListener('message', handleMessage);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isEmbeddingAllowed]);

  // Prevent rendering the rest of the app if embedding is not allowed
  if (!isEmbeddingAllowed) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px', color: 'white' }}>
        <h1>Embedding Not Allowed</h1>
        <p>This application cannot be embedded in the current domain.</p>
      </div>
    );
  }

  return (
    <div className="App scrollbar">
      <Navbar />

      <Routes>
        <Route path="/" element={<Navigate to="/BTC" />} />
        <Route path="/:coin" element={<HomePage />} />
        <Route
          path="/casino/dashboard/"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoStatistics />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/crypto-coins"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoCryptocoins />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/users"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoUsers />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/bots"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoBots />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/bets"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoBets />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/colors"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoColors />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/colors/edit"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoColorsEdit />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/casino/dashboard/settings"
          element={
            <IsPrivate>
              <IsCasino>
                <CasinoSettings />
              </IsCasino>
            </IsPrivate>
          }
        />
        <Route
          path="/profile"
          element={
            <IsPrivate>
              <ProfilePage />
            </IsPrivate>
          }
        />
      </Routes>
      <ToastContainer className='mr-5' />
    </div>
  );
}

export default App;
