// src/services/balanceService.js

import axios from 'axios';

// Define parent origin (use environment variables for flexibility)
const parentOrigin = process.env.REACT_APP_PARENT_ORIGIN || 'http://localhost:5173';

// Function to send messages to the parent window
const sendMessageToParent = (message) => {
    window.parent.postMessage(message, parentOrigin);
};

/**
 * Fetches the user's balance from the backend API.
 * @returns {Promise<number>} The user's balance.
 * @throws Will throw an error if fetching fails.
 */
export const fetchBalance = async () => {
    try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem('casinoToken');

        // Check if the token exists
        if (!token) {
            throw new Error('Authentication token not found. Please log in.');
        }

        // Make the GET request with the Authorization header
        const response = await axios.get(`https://casino-test-nine.vercel.app/user/balance`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const balance = response.data.balance;

        return balance;
    } catch (error) {
        console.error('Error fetching balance:', error);

        // Extract error message
        const errorMessage = error.response?.data?.message || 'Network Error or Server Unreachable.';

        // Throw a new error to be handled by the caller
        throw new Error(errorMessage);
    }
};

/**
 * Fetches the user's balance and sends it to the parent window.
 * @returns {Promise<number>} The user's balance.
 * @throws Will throw an error if fetching fails.
 */
export const sendBalanceToParent = async () => {
    try {
        const balance = await fetchBalance();

        // Prepare the balance message
        const balanceMessage = {
            type: 'BALANCE',
            payload: {
                balance,
            },
        };

        // Send the balance to the parent window
        sendMessageToParent(balanceMessage);

        return balance;
    } catch (balanceError) {
        console.error('Error fetching balance:', balanceError);

        // Prepare the error message
        const balanceErrorMessage = {
            type: 'ERROR',
            payload: {
                status: 'failed',
                message: balanceError.message || 'Unable to fetch user balance.',
            },
        };

        // Send the error to the parent window
        sendMessageToParent(balanceErrorMessage);

        // Re-throw the error if needed elsewhere
        throw balanceError;
    }
};
